<template>
  <div class="registration-form-container flex-column flex-wrap d-flex justify-center align-center">
    <v-stepper v-model="registrationStep">
      <v-stepper-header>
        <v-stepper-step
          :complete="registrationStep > 1"
          step="1"
        >
          Inserisci il numero di telefono
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="registrationStep > 2"
          step="2"
        >
          Conferma il numero di telefono
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Inserisci i tuoi dati
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form v-model="firstStepformValid" class="mb-5">
            <div class="mb-4">Inserisci il tuo numero di telefono:</div>
            <div>
              <v-text-field
                type="text"
                filled
                dense
                rounded
                hide-details="auto"
                v-model="phoneNumber"
                prefix="+39"
                :rules="[phoneNumberRule]"
              ></v-text-field>
            </div>
          </v-form>
          <v-btn
            color="primary"
            @click="handlePhoneNumberConfirmation"
            :disabled="!firstStepformValid"
            :loading="loadingSendCode"
          >
            Continua
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form v-model="secondStepformValid" class="mb-5">
            <div class="mb-4">Inserisci il tuo codice che ti abbiamo inviato per SMS:</div>
            <div>
              <v-text-field
                type="text"
                filled
                dense
                rounded
                hide-details="auto"
                v-model="confirmationCode"
                :rules="[presenceRule]"
              ></v-text-field>
            </div>
          </v-form>
          <v-btn
            color="primary"
            @click="handleConfirmCode"
            :disabled="!secondStepformValid"
            :loading="loadingConfirmCode"
          >
            Continua
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-form v-model="thirdStepformValid" class="mb-5">
            <v-row>
              <v-col>
                <v-text-field
                  type="text"
                  filled
                  dense
                  rounded
                  hide-details="auto"
                  v-model="salonName"
                  label="Nome Insegna"
                  :rules="[presenceRule]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="text"
                  filled
                  dense
                  rounded
                  hide-details="auto"
                  v-model="salonAddress"
                  label="Indirizzo Insegna"
                  :rules="[presenceRule]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="text"
                  filled
                  dense
                  rounded
                  hide-details="auto"
                  v-model="userFirstname"
                  label="Nome Referente"
                  :rules="[presenceRule]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="text"
                  filled
                  dense
                  rounded
                  hide-details="auto"
                  v-model="userLastname"
                  label="Cognome Referente"
                  :rules="[presenceRule]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="text"
                  filled
                  dense
                  rounded
                  hide-details="auto"
                  v-model="userEmail"
                  label="Email Referente"
                  :rules="[presenceRule]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="text"
                  filled
                  dense
                  rounded
                  hide-details="auto"
                  v-model="userPhone"
                  label="Telefono Referente"
                  :rules="[presenceRule]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  filled
                  dense
                  rounded
                  hide-details="auto"
                  v-model="licensePreset"
                  label="Seed"
                  :rules="[presenceRule]"
                  :items="presetTypes"
                  item-text="name"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <v-btn
            color="primary"
            @click="handleConfirmRegistrationData"
            :loading="loadingRegister"
          >
            Conferma
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import userService from '../services/users/users.service.js'
import { v4 as uuidv4 } from "uuid";

export default {
  name: 'Registration',
  data: function () {
    return {
      phoneNumber: undefined,
      confirmationCode: undefined,
      loadingSendCode: false,
      loadingConfirmCode: false,
      loadingRegister: false,
      registrationStep: 1,
      salonName: undefined,
      salonAddress: undefined,
      licenseSchemaName: undefined,
      licensePreset: undefined,
      userEmail: undefined,
      userPhone: undefined,
      userFirstname: undefined,
      userLastname: undefined,
      phoneNumberRule: (v) => {
        if(!v) return 'Il campo è obbligatorio'
        else if(!!v && isNaN(Number(v))) return 'Inserire solo caratteri numerici'
        else if(!!v && v.length != 10) return 'Inserire un numero di telefono valido'
        else return true
      },
      presenceRule: (v) => {
        if (!v) return 'Il campo è obbligatorio'
        else return true
      },
      firstStepformValid: false,
      secondStepformValid: false,
      thirdStepformValid: false,
      presetTypes: [
        { value: 'franchising', name: 'Franchising' },
        { value: 'newCustomer', name: 'New Customer (Parrucchiere + Estetica)' },
        { value: 'newCustomerBeauty', name: 'New Customer (Estetica)' },
        { value: 'newCustomerBarber', name: 'New Customer (Parrucchiere)' },
      ],
    }
  },
  methods: {
    async handlePhoneNumberConfirmation() {
      this.loadingSendCode = true
      await userService.requestConfirmNumberCode({ phoneNumber: this.phoneNumber})
      this.registrationStep = 2
    },
    async handleConfirmCode() {
      this.loadingConfirmCode = true
      const isValidResults = await userService.isConfirmNumberCodeValid({ phoneNumber: this.phoneNumber, code: this.confirmationCode })
      if(!!isValidResults.valid) {
        this.registrationStep = 3
      } else {
        this.$delegates.snackbar('il codice non è valido')
      }
    },
    async handleConfirmRegistrationData() {
      this.loadingRegister = true

      let flatLicenseName = this.salonName.toLowerCase().replaceAll(/\W/g, '')
      await userService.register({
        phoneNumber: this.phoneNumber,
        code: this.confirmationCode,
        licenseInfos: {
          name: this.salonName,
          schemaName: flatLicenseName + uuidv4().replaceAll('-', ''),
          preset: this.licensePreset,
          salonAddress: this.salonAddress,
          salonName: this.salonName
        },
        user: {
          email: this.userEmail,
          firstname: this.userFirstname,
          lastname: this.userLastname,
          phoneNumber: this.userPhone
        }
      }).then(() => {
        this.$delegates.snackbar('Registrazione avvenuta con successo! Resetta la password del tuo nuovo utente e accedi.')
        this.$router.push({ name: 'Login' })
      }).catch((err) => {
        this.$delegates.snackbar('Errore durante la registrazione')
      })
    }
  }
}
</script>

<style>
  .registration-form-container {
    height: 100dvh;
    width: 100dvw;
  }
</style>